import React, { useState, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchConToken } from "../helpers/fecth";
import { setRecoveryEmail } from "../redux/slices/auth/appSlice";

const RecoverPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const sendEmail = async () => {
      const response = await fetchConToken("recovery", { email }, "POST");
      const data = await response.json();

      if (data.success) {
        navigate("/enter_code");
      }
    };
    sendEmail();

    dispatch(setRecoveryEmail(email));
  };

  return (
    <div className="h-screen flex justify-center items-center bg-blue">
      <form
        onSubmit={handleSubmit}
        className="bg-gray-200 border rounded-2xl p-9 lg:w-[400px] w-[340px]"
      >
        <h3 className="text-2xl text-grey font-semibold text-center">
          Reestablecer contraseña
        </h3>
        <div className="my-8">
          <label className="text-grey mb-2">Correo</label>
          <input
            className="w-full rounded-3xl p-2 px-4 border mt-2"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="w-full flex justify-center">
          <button className="text-sm font-semibold rounded-2xl w-2/3 text-white tracking-wider border-0 min-h-[40px] mb-10 bg-blue bg-repeat-x">
            Recuperar
          </button>
        </div>
      </form>
    </div>
  );
};

export default RecoverPassword;
