import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import "../PaginationStyle.css";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Footer } from "./Footer";
import { Cards } from "./Cards";
import {
  filterProducts,
  paginationProducts,
  searchProducts,
} from "../../redux/slices/products/thunks";
import { setIsLoading } from "../../redux/slices/auth/appSlice";
import { SpinnerLoading } from "../SpinnerLoading";
import { RootState } from "../../redux/store";

interface PaginationProps {
  supermarketId: number;
  reloadPage: boolean;
  brand: string;
  categorieId: string;
  subCategory: string;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

export const Pagination: React.FC<PaginationProps> = ({
  supermarketId,
  reloadPage,
  brand,
  categorieId,
  subCategory,
  pageNumber,
  setPageNumber,
}) => {
  const dispatch = useDispatch();

  const { supermarketName, categoriesId, isLoading } =
    useSelector((state: RootState) => state.app);

  const [pageSize, setPageSize] = useState(6);

  const { listOfProducts, pagination, search } = useSelector(
    (state: RootState) => state.products
  );

  const debouncedQuery = useRef(
    _.debounce(
      (
        supermarketId: number | null,
        supermarketName: string | null,
        categoriesId: string | null,
        pageSize: number,
        pageNumber: number,
        search: string
      ) =>
        dispatch(
          searchProducts({
            supermarketId,
            supermarketName,
            categoriesId,
            pageSize,
            pageNumber,
            search,
          }) as any
        ),
      500
    )
  ).current;

  useEffect(() => {
    dispatch(setIsLoading(true));

    if (!search && categorieId !== "" && subCategory !== "" && brand !== "") {
      dispatch(setIsLoading(true));
      dispatch(
        paginationProducts({
          supermarketId,
          supermarketName,
          categoriesId,
          pageSize,
          pageNumber,
          subCategory,
          brand,
        }) as any
      );
    } else if (categorieId !== "" || subCategory !== "" || brand !== "") {
      dispatch(
        filterProducts({
          supermarketId,
          supermarketName,
          categoriesId,
          categorieId,
          subCategory,
          brand,
          pageSize,
          pageNumber,
        }) as any
      );
      dispatch(setIsLoading(true));
    } else {
      dispatch(setIsLoading(true));
      debouncedQuery(
        supermarketId,
        supermarketName,
        categoriesId,
        pageSize,
        pageNumber,
        search
      );
    }
  }, [
    dispatch,
    pageSize,
    pageNumber,
    supermarketId,
    supermarketName,
    categoriesId,
    reloadPage,
    search,
    debouncedQuery,
    categorieId,
    subCategory,
    brand,
  ]);

  const { totalPages } = pagination;

  const showData = listOfProducts?.map((item) => {
    return (
      <Cards
        supermarketId={supermarketId}
        key={item.id}
        productId={item.id}
        {...item}
      />
    );
  });

  const pageCount = totalPages;

  const changePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected);
  };

  return (
    <>
      {listOfProducts?.length === 0 ? (
        <h1 className="text-center">No hay productos</h1>
      ) : (
        <>
          {isLoading ? <SpinnerLoading /> : <>{showData}</>}
          <div className="mb-1 flex flex-row justify-between w-full">
            <Footer pageSize={pageSize} setPageSize={setPageSize} />
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              marginPagesDisplayed={0}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </>
      )}
    </>
  );
};
