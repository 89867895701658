import { useEffect, useState } from 'react';
import { fetchConToken } from '../helpers/fecth';

interface FetchResult<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
}

export const useFetch = <T,>(url: string): FetchResult<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await fetchConToken(url);
        const body: T = await resp.json();
        setData(body);
        setLoading(false);
      } catch (error) {
        setError(error as Error);
        setLoading(false);
      }
    };
    getData();
  }, [url]);

  return { data, loading, error };
};
