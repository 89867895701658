import React, { useState, FormEvent } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fecth";
import { RootState } from "../redux/store"; // Assuming you have a RootState type defined in your store

const RecoverPassword: React.FC = () => {
  const { email, code } = useSelector((state: RootState) => state.app);
  const navigate = useNavigate();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [error, setError] = useState(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (password1 === password2) {
      const password = password1;
      const sendEmail = async () => {
        const response = await fetchConToken(
          "recovery",
          { email, code, password },
          "PATCH"
        );
        const data = await response.json();

        if (data.success) {
          Swal.fire("Success", data.msg, "success");
          navigate("/");
        }
      };
      sendEmail();
    } else {
      setError(true);
    }
  };

  return (
    <div className="h-screen flex justify-center items-center bg-blue">
      <form
        onSubmit={handleSubmit}
        className="bg-gray-200 border rounded-2xl p-9 lg:w-[400px] w-[340px]"
      >
        <h3 className="text-2xl text-grey font-semibold text-center">
          Reestablecer Contraseña
        </h3>
        <div className="my-8">
          <label className="text-grey mb-2">Contraseña</label>
          <input
            className="w-full rounded-3xl p-2 border my-2"
            type="password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            required
          />
          <label className="text-grey mb-2">Confirmar contraseña</label>
          <input
            className="w-full rounded-3xl p-2 border mt-2"
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            required
          />
          {error && (
            <p className="text-red-500 text-sm mt-2">
              Las contraseñas no coinciden
            </p>
          )}
        </div>
        <div className="w-full flex justify-center">
          <button className="text-sm font-semibold rounded-2xl w-2/3 text-white tracking-wider border-0 min-h-[40px] mb-10 bg-blue bg-repeat-x">
            Recuperar
          </button>
        </div>
      </form>
    </div>
  );
};

export default RecoverPassword;
