import React from 'react';
import { useNavigate } from 'react-router-dom';

export const ModalAuth: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 bg-gray-400 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="w-full h-full flex justify-center items-center my-28">
        <div className="mx-auto w-3/4 md:w-2/4 lg:w-2/5 shadow-lg bg-gray-200 border-t-4 border-blue">
          <div className='bg-white text-center py-6'>
            <h1 className='text-xl'>Oooops.!</h1>
            <h1 className='text-xl'>La sesión ha expirado</h1>
            <button
              className='bg-blue text-white font-bold py-2 px-4 rounded mt-8'
              onClick={() => {
                localStorage.removeItem("token");
                navigate('/');
              }}
            >
              <p>Ir al inicio de sesión</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
