import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SpinnerLoading } from "../components/SpinnerLoading";
import { useFetch } from "../hooks/useFetch";
import { RiArrowLeftFill } from "react-icons/ri";
import {
  setSupermarketName,
  setSupermarketId,
} from "../redux/slices/auth/appSlice";
import { resetQuestion } from "../redux/slices/products/productSlice";
import { LogoutApp } from "../components/LogoutApp";
import Swal from "sweetalert2";

interface Supermarket {
  id: number;
  img: string;
  name: string;
}

interface FetchData {
  competitors?: Supermarket[];
}

const SupermarketsPage: React.FC = () => {
  const { countryId, questionId } = useParams<{
    countryId: string;
    questionId: string;
  }>();

  const { data: datos, loading } = useFetch<FetchData>(
    `competitors?countryId=${countryId}`
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToCategories = (name: string, id: number) => {
    dispatch(setSupermarketName(name));
    dispatch(setSupermarketId(id));
    navigate(
      `/country/${countryId}/question/${questionId}/supermarket/${id}/categories`
    );
  };

  return loading ? (
    <SpinnerLoading />
  ) : (
    <div className="h-full bg-gray-200 w-full flex flex-col justify-center items-center py-8 sm:my-0">
      <div className="flex justify-end w-4/5">
        <LogoutApp />
      </div>
      <div
        onClick={() => {
          Swal.fire({
            title: "¿Estás seguro?",
            text: "Si vuelves perderás el progreso de la pregunta actual y no se podrá recuperar.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, volver",
            cancelButtonText: "Cancelar",
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(resetQuestion());
              navigate(`/country/${countryId}/questions`);
            }
          });
        }}
        className="cursor-pointer"
      >
        <div className="flex items-center justify-center">
          <RiArrowLeftFill /> Volver
        </div>
      </div>
      <h3 className="my-8 text-grey font-bold">Menu de supermercados</h3>
      <div className="flex flex-wrap gap-4 w-4/5 justify-center items-center">
        {datos?.competitors && datos.competitors.length > 0 ? (
          datos.competitors.map((item: Supermarket) => (
            <button
              onClick={() => goToCategories(item.name, item.id)}
              key={item.id}
              className="flex flex-col justify-center items-center"
            >
              <div className="flex items-center px-2 w-36 h-36 bg-white rounded-md">
                <img className="w-full" src={item.img} alt={item.name} />
              </div>
              <p className="font-light text-center mt-2">{item.name}</p>
            </button>
          ))
        ) : (
          <p className="font-light text-center mt-2">
            No hay supermercados disponibles
          </p>
        )}
      </div>
    </div>
  );
};

export default SupermarketsPage;
