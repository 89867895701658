import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Product,
  editProducts,
  finishQuestion,
} from "../redux/slices/products/productSlice";
import product from "../assets/product.png";
import close from "../assets/close.svg";
import { RootState } from "../redux/store"; // Assuming you have a RootState type defined in your store
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

interface ModalCartProps {
  modal: boolean;
  setModal: (modal: boolean) => void;
}

export const ModalCart: React.FC<ModalCartProps> = ({ modal, setModal }) => {
  const { questionId, countryId } = useParams<{
    questionId: string;
    countryId: string;
  }>();

  const navigate = useNavigate();

  const { questions } = useSelector((state: RootState) => state.app);
  const selectedQuestion = questions.find(
    (question) => question.id === Number(questionId)
  );
  const { products } = useSelector((state: RootState) => state.products);
  const dispatch = useDispatch();

  const deleteProduct = (supermarketId: number, id: number) => {
    // eliminar el producto
    const newProducts = products[supermarketId].products.filter(
      (product: Product) => product.id !== id
    );
    // actualizar el state
    dispatch(
      editProducts({
        supermarketId,
        products: newProducts,
      })
    );
  };

  return products && Object.keys(products).length > 0 && modal ? (
    <div className="w-full absolute bg-white border-blue border-2 overflow-y-auto">
      <div className="flex flex-col gap-4 rounded-lg shadow-lg">
        <div className="flex flex-row justify-between items-center px-4 py-2 border-b border-blue">
          <p className="text-xl text-center text-blue font-semibold">
            {selectedQuestion?.question}
          </p>
          <button onClick={() => setModal(!modal)}>
            <img src={close} alt="" className="h-6 w-6" />
          </button>
        </div>
        <div className="flex flex-col gap-4 px-4 py-2">
          {Object.keys(products).map((tempSupermarketId: string) => {
            const intKey = parseInt(tempSupermarketId);
            return (
              <div key={intKey} className="flex flex-col gap-4">
                <p>Supermercado: {products[intKey].name}</p>
                {products[intKey].products.map((item: Product) => {
                  return (
                    <div
                      key={item.id}
                      className="flex flex-row mx-auto w-11/12 border-b pb-2"
                    >
                      <div className="flex flex-row w-full items-center gap-4">
                        <div className="w-20 h-20 rounded-lg shadow-lg border p-1">
                          <img
                            className="w-16 h-16 object-cover"
                            src={item.img ? item.img : product}
                            alt="products"
                          />
                        </div>
                        <div className="flex w-60 flex-col gap-2">
                          <p className="text-sm">{item.name}</p>
                          <button
                            onClick={() => deleteProduct(intKey, item.id)}
                            className="border w-24 border-red-600 rounded-2xl font-semibold text-red-600 hover:bg-red-600 hover:text-white"
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <hr />
              </div>
            );
          })}
        </div>
        <div className="mb-4 mx-auto w-3/4">
          <button
            onClick={() => {
              Swal.fire({
                title: "¿Estás seguro?",
                text: "¿Deseas finalizar la pregunta?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(finishQuestion(Number(questionId)));
                  setModal(!modal);
                  navigate(`/country/${countryId}/questions`);
                }
              });
            }}
            className="bg-blue text-white font-semibold rounded-2xl py-2 w-full"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  ) : null;
};
