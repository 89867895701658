import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Product {
  id: number;
  name: string;
  price: number;
  img?: string;
  value: string;
}

interface Pagination {
  size: number;
  currentPage: number;
  totalPages: number;
  totalResults: number;
}

export interface AnswerProduct {
  id: number;
  products: {
    [key: number]: SupermarketProducts;
  };
}

export interface SupermarketProducts {
  name: string;
  products: Product[];
}

interface ProductState {
  products: {
    [key: number]: SupermarketProducts;
  };
  search: string;
  listOfProducts: Product[];
  questionAnswers: AnswerProduct[];
  pagination: Pagination;
  quesionsAnswered: number[];
}

interface SetProductPayload {
  supermarketId: number;
  supermarketName: string;
  products: Product[];
}

interface EditProductPayload {
  supermarketId: number;
  products: Product[];
}

const initialState: ProductState = {
  products: {},
  search: "",
  listOfProducts: [],
  quesionsAnswered: [],
  questionAnswers: [],
  pagination: {
    size: 6,
    totalResults: 0,
    currentPage: 1,
    totalPages: 1,
  },
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<SetProductPayload>) => {
      if (state.products[action.payload.supermarketId]) {
        state.products[action.payload.supermarketId].products = action.payload.products;
      }
      else {
        state.products[action.payload.supermarketId] = {
          name: action.payload.supermarketName,
          products: action.payload.products,
        };
      }
    },
    resetSurvey: (state) => {
      state.products = {};
      state.quesionsAnswered = [];
      state.questionAnswers = [];
      state.search = "";
      state.listOfProducts = [];
    },
    resetQuestion: (state) => {
      state.products = {};
      state.search = "";
      state.listOfProducts = [];
    },
    resetProducts: (state) => {
      state.search = "";
      state.listOfProducts = [];
    },
    editProducts: (state, action: PayloadAction<EditProductPayload>) => {
      state.products[action.payload.supermarketId].products = action.payload.products;
      if (state.products[action.payload.supermarketId].products.length === 0) {
        const newState: {
          [key: number]: SupermarketProducts;
        } = {};
        Object.keys(state.products).forEach((key) => {
          const intKey = parseInt(key);
          if (intKey !== action.payload.supermarketId) {
            newState[intKey] = state.products[intKey];
          }
        });
        state.products = newState;
      }
    },
    setListOfProducts: (state, action: PayloadAction<Product[]>) => {
      state.listOfProducts = action.payload;
    },
    setPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = action.payload;
    },
    setSearchProducts: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    finishQuestion: (state, action: PayloadAction<number>) => {
      state.quesionsAnswered.push(action.payload);
      state.questionAnswers.push({
        id: action.payload,
        products: state.products,
      });
      state.products = {};
      state.search = "";
      state.listOfProducts = [];
    },
    setQuestionAnswered: (state, action: PayloadAction<number>) => {
      state.quesionsAnswered.push(action.payload);
    }
  },
});

export const {
  setProducts,
  resetProducts,
  resetSurvey,
  resetQuestion,
  editProducts,
  setListOfProducts,
  setPagination,
  setSearchProducts,
  finishQuestion,
  setQuestionAnswered,
} = productSlice.actions;

export default productSlice.reducer;
