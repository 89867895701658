import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface FooterProps {
  setPageSize: (size: number) => void;
  pageSize: number;
}

export const Footer: React.FC<FooterProps> = ({ setPageSize, pageSize }) => {
  const { pagination } = useSelector((state: RootState) => state.products);

  const { currentPage, size, totalResults } = pagination;

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(Number(e.target.value));
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex">
          <div className="flex justify-between m-5 text-gray-500 font-semibold bg-gray-100 py-1 px-0 rounded-lg xl:px-1">
            <select
              onChange={handleSelect}
              className="cursor-pointer bg-gray-100 border-0 outline-none py-2 px-0 tablet:px-2"
              value={pageSize}
            >
              <option value="6">6</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="40">40</option>
            </select>
          </div>
          <p className="text-1s mt-8 font-medium text-gray-400 hidden lg:block lg:text-2s">
            Mostrando registros del {(currentPage - 1) * size + 1} al {currentPage * size} de {totalResults}
          </p>
        </div>
      </div>
    </>
  );
};
