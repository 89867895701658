import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Product, setProducts } from "../../redux/slices/products/productSlice";
import { RootState } from "../../redux/store";

interface CardsProps {
  supermarketId: number;
  img?: string;
  productId: number;
  name: string;
  description?: string;
  price: number;
}

export const Cards: React.FC<CardsProps> = ({
  supermarketId,
  img,
  productId,
  name,
  description,
  price,
}) => {
  const { products } = useSelector((state: RootState) => state.products);
  const { supermarketName } = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();

  const addProductsToCart = (
    supermarketId: number,
    id: number,
    name: string,
    price: number,
    img?: string
  ) => {
    const validateId =
      products && products[supermarketId]
        ? products[supermarketId].products.find(
            (item: Product) => item.id === id
          )
        : false;

    if (!validateId) {
      if (products && products[supermarketId]) {
        dispatch(
          setProducts({
            supermarketId,
            supermarketName: supermarketName!,
            products: [
              ...products[supermarketId].products,
              {
                id,
                name,
                price,
                img,
                value: "",
              },
            ],
          })
        );
      } else {
        dispatch(
          setProducts({
            supermarketId,
            supermarketName: supermarketName!,
            products: [
              {
                id,
                name,
                price,
                img,
                value: "",
              },
            ],
          })
        );
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El producto ya se encuentra en el carrito",
      });
    }
  };

  // Validate if the product is in the cart
  const validateId =
    products && products[supermarketId]
      ? products[supermarketId].products.find(
          (item: Product) => item.id === productId
        )
      : false;

  return (
    <div key={productId} className="w-40 sm:w-60 flex">
      <div className="p-2 flex flex-col justify-between rounded-lg">
        <div className="rounded-xl w-36 h-60 items-center flex justify-center py-2">
          <img
            className="w-full h-full object-contain rounded-2xl"
            src={img}
            alt="productos"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                "https://mda.automercado.cr/mercategorias/productDefaultImage.jpeg";
            }}
          />
        </div>
        <div className="text-center">
          <p className="mt-4 text-sm uppercase">{`${name} ${
            description || ""
          }`}</p>
          {!!validateId ? (
            <div className="bg-blue text-white text-center font-bold py-1 px-4 rounded-2xl mt-4">
              Agregado
            </div>
          ) : (
            <button
              onClick={() =>
                addProductsToCart(supermarketId, productId, name, price, img)
              }
              className="border rounded-2xl py-1 font-semibold border-blue text-blue w-full mt-4 hover:bg-blue hover:text-white"
            >
              Agregar
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
