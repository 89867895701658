import React, { useEffect, useState, ReactNode } from "react";
import { ModalAuth } from "../pages/ModalAuth";

interface PrivateRoutesProps {
  children: ReactNode;
}

export const PrivateRoutes: React.FC<PrivateRoutesProps> = ({ children }) => {
  const token = localStorage.getItem("token");
  const [validToken, setValidToken] = useState(!!token);

  const validateToken = () => {
    if (!token) return false;

    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const tokenExpirationDate = new Date(decodedToken.exp * 1000);

      const now = new Date();

      if (now > tokenExpirationDate) {
        localStorage.removeItem("token");
        setValidToken(false);
        return false;
      } else {
        setValidToken(true);
        return true;
      }
    } catch (error) {
      console.error("Failed to decode token", error);
      localStorage.removeItem("token");
      setValidToken(false);
      return false;
    }
  };

  useEffect(() => {
    if (!token) return;
    validateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return validToken ? <>{children}</> : <ModalAuth />;
};
