import React from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import closeSesion from "../assets/cerrar-sesion.png";
import { logout } from "../redux/slices/auth/thunks";

export const LogoutApp: React.FC = () => {
  const dispatch = useDispatch();

  const handleCloseSession = () => {
    Swal.fire({
      title: "Cerrar sesión",
      text: "¿Estas seguro de cerrar sesión?",
      showDenyButton: true,
      confirmButtonText: `Si`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout() as any);
      }
    });
  };

  return (
    <div className="my-auto flex flex-row gap-4">
      <button
        className="flex justify-center p-2.5 items-center rounded-full w-10 h-10"
        onClick={handleCloseSession}
      >
        <img className="w-full" src={closeSesion} alt="Cerrar sesión" />
      </button>
    </div>
  );
};
