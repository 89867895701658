import React, { useState } from "react";
import { Header } from "../components/Header";
import button from "../assets/button.svg";
import { ModalCategories } from "../components/ModalCategories";
import { Pagination } from "../components/Ecommerce/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { RiLoader3Fill } from "react-icons/ri";
import { setSearchProducts } from "../redux/slices/products/productSlice";
import { RootState } from "../redux/store"; // Assuming you have a RootState type defined in your store

const Ecommerce: React.FC = () => {
  const { countryId, questionId, supermarketId } = useParams<{
    countryId: string;
    questionId: string;
    supermarketId: string;
    categoryId: string;
  }>();

  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const { categoriesId } = useSelector((state: RootState) => state.app);
  const [animateSpin, setAnimateSpin] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const [brandId, setBrandId] = useState("");
  const [categorieId, setCategorieId] = useState("");
  const [subCategorieId, setSubCategorieId] = useState("");

  const handleReloadPage = () => {
    dispatch(setSearchProducts(""));
    setReloadPage(!reloadPage);

    setBrandId("");
    setCategorieId("");
    setSubCategorieId("");
  };

  return (
    <>
      <Header />
      <ModalCategories
        modal={modal}
        setModal={setModal}
        categorieId={categorieId}
        subCategorieId={subCategorieId}
        brandId={brandId}
        setBrandId={setBrandId}
        setCategorieId={setCategorieId}
        setSubCategorieId={setSubCategorieId}
        setPageNumber={setPageNumber}
      />
      <div className="flex flex-col">
        <div className="w-10/12 mx-auto mt-4 flex flex-row justify-between">
          <div className="flex flex-row">
            <img
              onClick={() => setModal(true)}
              className="w-4 cursor-pointer"
              src={button}
              alt=""
            />
            <div className="flex flex-row">
              <Link
                className="cursor-pointer"
                to={`/country/${countryId}/question/${questionId}/supermarket/${supermarketId}/categories`}
              >
                <p className="text-blue ml-4">Categorías /</p>
              </Link>
              <p className="text-blue ml-1 lowercase">{categoriesId}</p>
            </div>
          </div>
          <div>
            <RiLoader3Fill
              className={`${
                animateSpin ? "animate-spin" : ""
              } text-blue ml-4 cursor-pointer`}
              size="24"
              onClick={handleReloadPage}
              onMouseEnter={() => setAnimateSpin(true)}
              onMouseLeave={() => setAnimateSpin(false)}
            />
          </div>
        </div>
        <div className="w-full mt-8">
          <div className="flex flex-wrap gap-2 w-11/12 justify-around mx-auto ">
            <Pagination
              supermarketId={parseInt(supermarketId!)}
              reloadPage={reloadPage}
              brand={brandId}
              categorieId={categorieId}
              subCategory={subCategorieId}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Ecommerce;
