import React, { useState, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchConToken } from "../helpers/fecth";
import { setRecoveryCode } from "../redux/slices/auth/appSlice";
import { RootState } from "../redux/store"; // Assuming you have a RootState type defined in your store

const EnterCode: React.FC = () => {
  const { email } = useSelector((state: RootState) => state.app);

  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const sendEmail = async () => {
      const response = await fetchConToken("recovery", { email, code }, "POST");
      const data = await response.json();

      if (data.success) {
        navigate("/reset_password");
        dispatch(setRecoveryCode(code));
      }
    };

    sendEmail();
  };

  return (
    <div className="h-screen flex justify-center items-center bg-blue">
      <form
        onSubmit={handleSubmit}
        className="bg-gray-200 border rounded-2xl p-9 lg:w-[400px] w-[340px]"
      >
        <h3 className="text-2xl text-grey font-semibold text-center">
          Insertar código
        </h3>
        <div className="my-8">
          <label className="text-grey mb-2">Código</label>
          <input
            className="w-full rounded-3xl p-2 border my-2"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </div>
        <div className="w-full flex justify-center">
          <button className="text-sm font-semibold rounded-2xl w-2/3 text-white tracking-wider border-0 min-h-[40px] mb-10 bg-blue bg-repeat-x">
            Ingresar
          </button>
        </div>
      </form>
    </div>
  );
};

export default EnterCode;
