import Swal from "sweetalert2";
import { fetchConToken } from "../../../helpers/fecth";
import { AppDispatch } from "../../store"; // Assuming you have this type defined
import { setIsLoading } from "../auth/appSlice";
import {
  AnswerProduct,
  resetProducts,
  resetSurvey,
  setListOfProducts,
  setPagination,
} from "./productSlice";

interface FetchProductsParams {
  supermarketId: number | null;
  supermarketName: string | null;
  categorieId?: string;
  categoriesId: string | null;
  pageSize?: number;
  pageNumber?: number;
  subCategory?: string;
  brand?: string;
  search?: string;
  categories?: string;
  subCategories?: string;
}

export const paginationProducts =
  (params: FetchProductsParams) => async (dispatch: AppDispatch) => {
    const {
      supermarketId,
      supermarketName,
      categoriesId,
      pageSize,
      pageNumber,
      subCategory,
      brand,
    } = params;
    try {
      const resp = await fetchConToken(
        `competitors/${supermarketId}/products?search&page=${pageNumber}&size=${pageSize}&competitor=${supermarketName}&typeProduct=${categoriesId}${subCategory ? `&subCategoria=${subCategory}` : ""
        }${brand ? `&brand=${brand}` : ""}`
      );
      const data = await resp.json();

      if (resp.status === 201) {
        dispatch(setListOfProducts(data.products));
        dispatch(setPagination({
          currentPage: data.pagination.current,
          size: data.pagination.size,
          totalPages: data.pagination.total_pages,
          totalResults: data.pagination.total_results
        }));
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setIsLoading(false));
    }
  };

export const searchProducts =
  (params: FetchProductsParams) => async (dispatch: AppDispatch) => {
    const {
      supermarketId,
      supermarketName,
      categoriesId,
      pageSize,
      pageNumber,
      search,
      brand
    } = params;
    try {
      //`competitors/${distribuitorId}/products?search=${search}&page=${pageNumber}&size=${pageSize}&competitor=${competition}&typeProduct=${categories}${brandId ? `&brand=${brandId}` : ""}`
      const resp = await fetchConToken(
        `competitors/${supermarketId}/products?search=${search}&page=${pageNumber}&size=${pageSize}&competitor=${supermarketName}${categoriesId === "TODOS" ? "" : `&typeProduct=${categoriesId}`}${brand ? `&brand=${brand}` : ""}`
      );
      const data = await resp.json();

      if (resp.status === 201) {
        dispatch(setListOfProducts(data.products));
        dispatch(setPagination({
          currentPage: data.pagination.current,
          size: data.pagination.size,
          totalPages: data.pagination.total_pages,
          totalResults: data.pagination.total_results
        }));
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setIsLoading(false));
    }
  };

export const filterProducts =
  (params: FetchProductsParams) => async (dispatch: AppDispatch) => {
    const {
      supermarketId,
      supermarketName,
      categoriesId,
      categorieId,
      subCategory,
      brand,
      pageSize,
      pageNumber,
    } = params;
    try {
      const resp = await fetchConToken(
        `competitors/${supermarketId}/products?search&page=${pageNumber}&size=${pageSize}&competitor=${supermarketName}&typeProduct=${categoriesId}&categoria=${categorieId}${subCategory ? `&subCategoria=${subCategory}` : ""}${brand ? `&brand=${brand}` : ""
        }`
      );
      const data = await resp.json();

      if (resp.status === 201) {
        dispatch(setListOfProducts(data.products));

        dispatch(setPagination({
          currentPage: data.pagination.current,
          size: data.pagination.size,
          totalPages: data.pagination.total_pages,
          totalResults: data.pagination.total_results
        }));
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setIsLoading(false));
    }
  };

export const sendProduct =
  (interviewId: string, distribuitorId: string, answers: any[]) =>
    async (dispatch: AppDispatch) => {
      try {
        const resp = await fetchConToken(
          `form`,
          {
            distribuitorId,
            interviewId,
            answers,
          },
          "POST"
        );

        if (resp.status === 200) {
          dispatch(resetProducts());
          Swal.fire({
            title: "¡Excelente!",
            text: "Tu producto ha sido enviado correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/index";
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

export const saveResults = (userId: number, answers: AnswerProduct[]) => async (dispatch: AppDispatch) => {
  try {
    const resp = await fetchConToken(
      `survey`,
      {
        userId,
        answers,
      },
      "POST"
    );

    if (resp.status === 200) {
      const data = await resp.json();
      Swal.fire({
        title: "¡Excelente!",
        text: `Tus respuestas han sido guardadas correctamente. El número de encuesta para STG es: ${data.data.id}`,
        icon: "success",
        confirmButtonText: "Aceptar",
      }).then((result) => {
        dispatch(resetSurvey());
        window.location.href = "/countries";
      });
    }
  } catch (error) {
    console.error(error);
  }
};