import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Question {
  id: number;
  question: string;
  allowSuperMarketChange: boolean;
  createdAt: Date;
  updatedAt: Date;
}

interface AppState {
  access: Access | null;
  isLoading: boolean;
  supermarketName: string | null;
  supermarketId: number | null;
  categoriesId: string | null;
  categoriesIds: number | null;
  email: string;
  code: string;
  countryId: number | null;
  questions: Question[];
}

interface Access {
  token: string;
  id: string;
  correo: string;
  name: string;
  rol: string;
}

const initialState: AppState = {
  access: null,
  isLoading: false,
  supermarketName: null,
  supermarketId: null,
  categoriesId: null,
  categoriesIds: null,
  email: "",
  code: "",
  countryId: null, //TODO: BORRAR
  questions: []
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<Access>) => {
      state.access = action.payload;
    },
    setLogout: (state) => {
      state = initialState;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSupermarketName: (state, action: PayloadAction<string>) => {
      state.supermarketName = action.payload;
    },
    setSupermarketId: (state, action: PayloadAction<number>) => {
      state.supermarketId = action.payload;
    },
    setCategoriesId: (state, action: PayloadAction<string>) => {
      state.categoriesId = action.payload;
    },
    setCategoriesIds: (state, action: PayloadAction<number>) => {
      state.categoriesIds = action.payload;
    },
    setRecoveryEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setRecoveryCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    setQuestions: (state, action: PayloadAction<Question[]>) => {
      state.questions = action.payload;
    }
  },
});

export const {
  setLogin,
  setLogout,
  setIsLoading,
  setSupermarketName,
  setSupermarketId,
  setCategoriesId,
  setCategoriesIds,
  setRecoveryEmail,
  setRecoveryCode,
  setQuestions,
} = appSlice.actions;

export default appSlice.reducer;
