import { fetchConToken, fetchSinToken } from "../../../helpers/fecth";
import { setIsLoading, setLogin, setLogout, setQuestions } from "./appSlice";
import { AppDispatch } from "../../store";
import Swal from "sweetalert2";

interface LoginResponse {
  success: boolean;
  accessToken: string;
  user: {
    userId: string;
    email: string;
    fullName: string;
    rol: string;
  };
}

export const login = (email: string, password: string) => {

  return async (dispatch: AppDispatch) => {
    try {
      const resp = await fetchSinToken(
        'auth/login',
        {
          email,
          password,
        },
        'POST'
      );

      const body: LoginResponse = await resp.json();

      if (body.success === true) {
        localStorage.setItem("token", body.accessToken);
        localStorage.setItem("token-init-date", new Date().getTime().toString());

        dispatch(
          setLogin({
            token: body.accessToken,
            id: body.user.userId,
            correo: body.user.email,
            name: body.user.fullName,
            rol: body.user.rol,
          })
        );

        window.location.href = "/countries";

      } else {
        dispatch(setIsLoading(false));
        Swal.fire("error", "Usuario o contraseña incorrecta", "error");
      }
    } catch (error) {
      console.log(error);
    }

  };

}

export const logout = () => {
  return async (dispatch: AppDispatch) => {
    const resp = await fetchConToken("auth/logout", {}, "PATCH");

    if (resp.status === 204) {
      dispatch(setLogout());
      localStorage.clear();
      window.location.href = "/";
    }

    if (resp.status === 406) {
      dispatch(setLogout());
      localStorage.clear();
    }
    dispatch(setIsLoading(false));
  };
};

export const getQuestions = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const resp = await fetchConToken("question", {}, "GET");
      const body = await resp.json();
      dispatch(setQuestions(body.data));
    } catch (error) {
      console.log(error);
    }
    dispatch(setIsLoading(false));
  };
}
