const baseUrl = process.env.REACT_APP_API_URL;

const fetchSinToken = (endpoint: string, data?: any, method: string = "GET"): Promise<Response> => {
  const url = `${baseUrl}/${endpoint}`;
  
  if (method === "GET") {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};

const fetchConToken = (endpoint: string, data?: any, method: string = "GET"): Promise<Response> => {
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem("token") || "";
  
  if (method === "GET") {
    return fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
  }
};

export { fetchSinToken, fetchConToken };
