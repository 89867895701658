import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SupermarketsPage from "../pages/SupermarketsPage";
import Ecommerce from "../pages/Ecommerce";
import Login from "../pages/Login";
import RecoverPassword from "../pages/RecoverPassword";
import ResetPassword from "../pages/ResetPassword";
import EnterCode from "../pages/Code";
import Categories from "../pages/Categories";
import Countries from "../pages/Countries";
import { PrivateRoutes } from "./PrivateRoute";
import QuestionsSelector from "../pages/QuestionsSelector";

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login key={Math.random()} />} />
        <Route path="recover_password" element={<RecoverPassword />} />
        <Route path="reset_password" element={<ResetPassword />} />
        <Route path="enter_code" element={<EnterCode />} />

        {/* Private Routes */}
        <Route
          path="countries"
          element={
            <PrivateRoutes key={Math.random()}>
              <Countries />
            </PrivateRoutes>
          }
        />
        {/* Private Routes */}
        <Route
          path="country/:countryId/questions"
          element={
            <PrivateRoutes key={Math.random()}>
              <QuestionsSelector />
            </PrivateRoutes>
          }
        />
        <Route
          path="country/:countryId/question/:questionId/supermarkets"
          element={
            <PrivateRoutes key={Math.random()}>
              <SupermarketsPage />
            </PrivateRoutes>
          }
        />
        <Route
          path="country/:countryId/question/:questionId/supermarket/:supermarketId/categories"
          element={
            <PrivateRoutes key={Math.random()}>
              <Categories />
            </PrivateRoutes>
          }
        />
        <Route
          path="country/:countryId/question/:questionId/supermarket/:supermarketId/category/:categoryId/products"
          element={
            <PrivateRoutes key={Math.random()}>
              <Ecommerce />
            </PrivateRoutes>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
