import React, { useEffect, useState } from "react";
import lupa from "../assets/lupa.svg";
import carrito from "../assets/carrito-de-compras.png";
import closeSesion from "../assets/cerrar-sesion.png";
import { ModalCart } from "./ModalCart";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/slices/auth/thunks";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import {
  resetProducts,
  setSearchProducts,
} from "../redux/slices/products/productSlice";
import { RootState } from "../redux/store";
import { RiArrowLeftFill } from "react-icons/ri";

export const Header: React.FC = () => {
  const { countryId, questionId, supermarketId } = useParams<{
    countryId: string;
    questionId: string;
    supermarketId: string;
  }>();

  const intSupermarketId = parseInt(supermarketId!);

  // Inicializamos el estado del modal y del buscador
  const [modal, setModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  // Inicializamos el dispatch
  const dispatch = useDispatch();

  // Obtenemos el estado del carrito y obtenemos los datos de las categorias y de los competidores
  const { products } = useSelector((state: RootState) => state.products);
  const { supermarketName, questions } = useSelector(
    (state: RootState) => state.app
  );

  const selectedQuestion = questions.find(
    (question) => question.id === Number(questionId)
  );

  // Hacemos un useEffect para unicamente ejecutar la funcion de buscar productos cuando se cambie el estado del buscador
  useEffect(() => {
    dispatch(setSearchProducts(search));
  }, [search, dispatch]);

  // Funcion para cerrar el modal cuando no hay productos en el carrito
  useEffect(() => {
    if (
      products &&
      products[intSupermarketId] &&
      products[intSupermarketId].products.length === 0
    ) {
      setModal(false);
    }
  }, [products]);

  // Funcion para cerrar sesion
  const handleCloseSession = () => {
    Swal.fire({
      title: "Cerrar sesión",
      text: "¿Estas seguro de cerrar sesión?",
      showDenyButton: true,
      confirmButtonText: `Si`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout() as any);
      }
    });
  };

  const countProductsInCart = () => {
    let count = 0;
    Object.keys(products).forEach((key) => {
      if (products[parseInt(key)].products) {
        count += products[parseInt(key)].products.length;
      }
    });
    return count;
  };

  return (
    <>
      <div className="py-4 bg-blue flex flex-col sm:flex-row justify-between items-center px-6 border-b gap-4">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row gap-1 w-full text-white">
            <p>{supermarketName}</p>
            {selectedQuestion?.allowSuperMarketChange ? (
              <Link
                onClick={() => {
                  dispatch(resetProducts());
                }}
                to={`/country/${countryId}/question/${questionId}/supermarkets`}
              >
                (Cambiar supermercado)
              </Link>
            ) : (
              <Link
                onClick={() => {
                  dispatch(resetProducts());
                }}
                to={`/country/${countryId}/question/${questionId}/supermarket/${supermarketId}/categories`}
              >
                <div className="flex items-center justify-center">
                  <RiArrowLeftFill className="cursor-pointer" /> Volver
                </div>
              </Link>
            )}
          </div>
          <div className="my-auto flex flex-row gap-4">
            <button
              className="bg-white flex justify-center p-2.5 items-center relative rounded-full w-10 h-10"
              onClick={() => setModal(!modal)}
            >
              <img className="w-full" src={carrito} alt="" />
              <div className="absolute top-0 right-0 bg-red-500 rounded-full w-4 h-4 flex justify-center items-center">
                <p className="text-white text-xs">{countProductsInCart()}</p>
              </div>
            </button>
            <button
              className="bg-white flex justify-center p-2.5 items-center rounded-full w-10 h-10"
              onClick={handleCloseSession}
            >
              <img className="w-full" src={closeSesion} alt="" />
            </button>
          </div>
        </div>
        <div className="flex flex-row w-full">
          <div className="border flex flex-row justify-center items-center py-2 px-4 w-full bg-white rounded-2xl">
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full outline-none"
              type="text"
              placeholder="Busque su producto"
            />
            <img src={lupa} alt="lupa" />
          </div>
        </div>
      </div>
      <ModalCart modal={modal} setModal={setModal} />
    </>
  );
};
