import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftFill } from "react-icons/ri";
import { SpinnerLoading } from "../components/SpinnerLoading";
import { useFetch } from "../hooks/useFetch";
import {
  setCategoriesId,
  setCategoriesIds,
} from "../redux/slices/auth/appSlice";
import { Product, resetProducts } from "../redux/slices/products/productSlice";
import { LogoutApp } from "../components/LogoutApp";
import { RootState } from "../redux/store"; // Assuming you have a RootState type defined in your store
import Swal from "sweetalert2";
import todas from "../assets/todas.png";

interface FetchData {
  productType?: Product[];
}

const Categories: React.FC = () => {
  const { countryId, questionId, supermarketId } = useParams<{
    countryId: string;
    questionId: string;
    supermarketId: string;
  }>();

  const { supermarketName, questions } = useSelector(
    (state: RootState) => state.app
  );

  const selectedQuestion = questions.find(
    (question) => question.id === Number(questionId)
  );

  const { data: datos, loading } = useFetch<FetchData>(
    `catalog/type-product?distribuitorId=${supermarketId}`
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToEcommerce = (categoryName: string, id: number) => {
    dispatch(setCategoriesId(categoryName));
    dispatch(setCategoriesIds(id));
    navigate(
      `/country/${countryId}/question/${questionId}/supermarket/${supermarketId}/category/${id}/products`
    );
  };

  return loading ? (
    <SpinnerLoading />
  ) : (
    <div className="min-h-screen bg-gray-200 w-full flex flex-col justify-center items-center py-8 sm:my-0">
      <div className="flex justify-end w-4/5">
        <LogoutApp />
      </div>
      {selectedQuestion?.allowSuperMarketChange ? (
        <Link
          onClick={() => {
            dispatch(resetProducts());
          }}
          to={`/country/${countryId}/question/${questionId}/supermarkets`}
        >
          <div className="flex items-center justify-center">
            <RiArrowLeftFill className="cursor-pointer" /> Volver
          </div>
        </Link>
      ) : (
        <div
          onClick={() => {
            Swal.fire({
              title: "¿Estás seguro de regresar?",
              text: "Si regresas perderás el progreso de esta pregunta y no se podrá recuperar. ¿Deseas continuar?",
              showDenyButton: true,
              confirmButtonText: `Si`,
              denyButtonText: `No`,
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(resetProducts());
                navigate(
                  `/country/${countryId}/question/${questionId}/supermarkets`
                );
              }
            });
          }}
          className="cursor-pointer"
        >
          <div className="flex items-center justify-center">
            <RiArrowLeftFill /> Volver
          </div>
        </div>
      )}
      <div className="flex items-center justify-center gap-4">
        <h2 className="text-xl font-bold my-8">{supermarketName}</h2>
      </div>
      <div className="flex flex-wrap gap-4 w-11/12 justify-center items-center">
        <div
          onClick={() => goToEcommerce("TODOS", 0)}
          className="flex flex-col justify-center items-center w-1/3 sm:w-1/4 md:w-1/6"
        >
          <div className="p-4 bg-white rounded-3xl border flex justify-center items-center hover:cursor-pointer hover:bg-gray-100">
            <img src={todas} alt="" className="w-20 h-20" />
          </div>
          <p className="font-light text-center mt-2">TODOS</p>
        </div>
        {datos?.productType?.map((item: Product) => (
          <div
            onClick={() => goToEcommerce(item.value, item.id)}
            key={item.id}
            className="flex flex-col justify-center items-center w-1/3 sm:w-1/4 md:w-1/6"
          >
            <div className="p-4 bg-white rounded-3xl border flex justify-center items-center hover:cursor-pointer hover:bg-gray-100">
              <img src={item.img} alt="" className="w-20 h-20" />
            </div>
            <p className="font-light text-center mt-2">{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
