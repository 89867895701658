import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SpinnerLoading } from "../components/SpinnerLoading";
import { setIsLoading } from "../redux/slices/auth/appSlice";
import { login } from "../redux/slices/auth/thunks";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface LoginFormInputs {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: RootState) => state.app);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>();

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    const { email, password } = data;
    dispatch(setIsLoading(true));
    dispatch(login(email, password) as any);
  };

  return isLoading ? (
    <SpinnerLoading />
  ) : (
    <div className="h-screen flex justify-center items-center bg-blue">
      <div className="border bg-gray-200 py-4 px-8 lg:w-[400px] w-[340px] rounded-2xl">
        <div className="text-center">
          <p className="text-3xl font-semibold text-gray">Iniciar sesión</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
          <div className="mb-4">
            <label className="text-grey mb-2">Nombre de usuario</label>
            <input
              className="w-full p-2 border rounded-2xl"
              placeholder="admin@admin.com"
              {...register("email", {
                required: {
                  value: true,
                  message: "Escribe tu usuario",
                },
                maxLength: {
                  value: 40,
                  message: "Máximo de caracteres 40",
                },
                minLength: {
                  value: 2,
                  message: "Mínimo de caracteres 2",
                },
              })}
            />
            <p className="text-red-900 text-1s">
              {errors.email && errors.email.message}
            </p>
          </div>
          <div className="mb-4">
            <label className="text-grey mb-2">Contraseña</label>
            <input
              className="w-full p-2 border rounded-2xl"
              type="password"
              placeholder="*******"
              {...register("password", {
                required: {
                  value: true,
                  message: "Escribe tu contraseña",
                },
                maxLength: {
                  value: 25,
                  message: "Máximo de caracteres 25",
                },
                minLength: {
                  value: 2,
                  message: "Mínimo de caracteres 2",
                },
              })}
            />
            <p className="text-red-900 text-1s absolute">
              {errors.password && errors.password.message}
            </p>
          </div>
          <Link to="recover_password">
            <p className="text-grey mb-4 hover:underline cursor-pointer text-xs">
              Recuperar contraseña
            </p>
          </Link>
          <div className="w-full flex items-center justify-center">
            <button className="text-sm bg-blue rounded-2xl w-2/3 text-white tracking-wider border-0 min-h-[40px] bg-gradient-to-r from-blue-400 to-purple-800 bg-repeat-x">
              Ingresar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
