import React, { useState } from "react";
import { useSelector } from "react-redux";
import arrow from "../assets/arrow-down.svg";
import close from "../assets/close.svg";
import { fetchConToken } from "../helpers/fecth";
import { useFetch } from "../hooks/useFetch";
import { RootState } from "../redux/store"; // Assuming you have a RootState type defined in your store

interface ModalCategoriesProps {
  modal: boolean;
  setModal: (modal: boolean) => void;
  categorieId: string;
  subCategorieId: string;
  brandId: string;
  setBrandId: (brandId: string) => void;
  setCategorieId: (categorieId: string) => void;
  setSubCategorieId: (subCategorieId: string) => void;
  setPageNumber: (pageNumber: number) => void;
}

interface Category {
  categoryId: string;
  categoryName: string;
}

interface SubCategory {
  subCategoryId: string;
  subCategoryName: string;
}

interface Brand {
  brandId: string;
  brand: string;
}

interface FetchData {
  categories?: Category[];
  subCategories?: SubCategory[];
  brands?: Brand[];
}

export const ModalCategories: React.FC<ModalCategoriesProps> = ({
  modal,
  setModal,
  categorieId,
  subCategorieId,
  brandId,
  setBrandId,
  setCategorieId,
  setSubCategorieId,
  setPageNumber,
}) => {
  const { categoriesIds, supermarketId } = useSelector((state: RootState) => state.app);

  const { data: datos } = useFetch<FetchData>(
    `catalog/type-product/${categoriesIds}?distribuitorId=${supermarketId}`
  );
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);

  // Estados para mostrar los datos
  const [brandState, setBrandState] = useState(false);
  const [subCategorieState, setSubCategorieState] = useState(false);
  const [categoriesState, setCategoriesState] = useState(false);

  const handleCategories = (value: string, id: string) => {
    setCategorieId(value);
    setSubCategorieId("");
    setBrandId("");
    setSubCategories([]);
    setBrands([]);
    setPageNumber(0);
    setCategoriesState(false);

    const getSubCategories = async () => {
      const resp = await fetchConToken(
        `catalog/type-product/${categoriesIds}?categoryId=${id}&distribuitorId=${supermarketId}`
      );
      const data = await resp.json();
      setSubCategories(data.subCategories || []);
    };

    getSubCategories();
  };

  const handleSubCategory = (id: string, subcategory: string) => {
    setSubCategorieId(subcategory);
    setBrandId("");
    setBrands([]);
    setPageNumber(0);
    setSubCategorieState(false);

    const getBrands = async () => {
      const resp = await fetchConToken(
        `catalog/view-brand?subCategoryId=${id}&distribuitorId=${supermarketId}`
      );
      const data = await resp.json();
      setBrands(data.brands || []);
    };

    getBrands();
  };

  const handleBrand = (brand: string) => {
    setBrandId(brand);
    setPageNumber(0);
    setBrandState(false);
    setModal(false);
  };

  return (
    modal ? (
      <div className="fixed inset-0 bg-gray-100 bg-opacity-70 h-full w-full overflow-y-auto z-50">
        <div className="flex justify-center items-center overflow-auto mt-40">
          <div className="mx-auto p-5 px-14 bg-gray-300 w-full h-screen overflow-auto pb-40 fixed bottom-0 top-40 shadow-lg rounded-md text-dark-blue">
            <div className="flex flex-row justify-between items-center pt-8">
              <p className="text-3xl text-center font-semibold">Filtros</p>
              <button onClick={() => setModal(!modal)}>
                <img src={close} alt="" className="h-8 w-8" />
              </button>
            </div>
            <div className="flex flex-col justify-start items-start">
              <button
                onClick={() => setCategoriesState(!categoriesState)}
                className="pt-4 border-b pb-2 w-full border-gray-700 flex flex-row justify-between items-center mb-2"
              >
                <p className="text-2xl">Categorias</p>
                <img src={arrow} alt="" />
              </button>
              {categoriesState &&
                (datos?.categories && datos.categories.length > 0 ? (
                  datos.categories.map((item) => (
                    <button
                      onClick={() => handleCategories(item.categoryName, item.categoryId)}
                      key={item.categoryId}
                      className={`flex flex-row justify-between ${
                        categorieId === item.categoryName
                          ? "bg-gray-400"
                          : "hover:bg-gray-400"
                      } px-2 rounded-lg items-center w-full py-2`}
                    >
                      <p className="text-xl">{item.categoryName}</p>
                    </button>
                  ))
                ) : (
                  <p>No hay categorías disponibles</p>
                ))}
              {subCategories.length > 0 ? (
                <>
                  <button
                    onClick={() => setSubCategorieState(!subCategorieState)}
                    className="pt-4 border-b pb-2 w-full border-gray-700 flex flex-row justify-between items-center mb-2"
                  >
                    <p className="text-2xl">Subcategorías</p>
                    <img src={arrow} alt="" />
                  </button>
                  {subCategorieState &&
                    subCategories.map((item) => (
                      <button
                        onClick={() => handleSubCategory(item.subCategoryId, item.subCategoryName)}
                        key={item.subCategoryId}
                        className={`flex flex-row justify-between ${
                          subCategorieId === item.subCategoryName
                            ? "bg-gray-400"
                            : "hover:bg-gray-400"
                        } px-2 rounded-lg items-center w-full py-2`}
                      >
                        <p className="text-xl">{item.subCategoryName}</p>
                      </button>
                    ))}
                </>
              ) : (
                <p className="mt-4">No hay sub categorías disponibles. Seleccione una nueva categoría para verificar sus sub categorías.</p>
              )}
              {brands.length > 0 && (
                <>
                  <button
                    onClick={() => setBrandState(!brandState)}
                    className="pt-4 border-b pb-2 w-full border-gray-700 flex flex-row justify-between items-center mb-2"
                  >
                    <p className="text-2xl">Marcas</p>
                    <img src={arrow} alt="" />
                  </button>
                  {brandState &&
                    brands.map((item) => (
                      <div
                        onClick={() => handleBrand(item.brand)}
                        key={item.brandId}
                        className={`flex flex-row justify-between ${
                          brandId === item.brand
                            ? "bg-gray-400"
                            : "hover:bg-gray-400"
                        } px-2 rounded-lg items-center w-full py-2`}
                      >
                        <p className="text-xl">{item.brand}</p>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    ) : null
  );
};
