import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LogoutApp } from "../components/LogoutApp";
import { resetSurvey, setQuestionAnswered } from "../redux/slices/products/productSlice";
import { RiArrowLeftFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { getQuestions } from "../redux/slices/auth/thunks";
import { SpinnerLoading } from "../components/SpinnerLoading";
import { saveResults } from "../redux/slices/products/thunks";

const QuestionsSelector: React.FC = () => {
  const { countryId } = useParams<{ countryId: string }>();
  const dispatch = useDispatch();

  const { quesionsAnswered, questionAnswers } = useSelector(
    (state: RootState) => state.products
  );

  const { questions, isLoading, access } = useSelector(
    (state: RootState) => state.app
  );

  const navigate = useNavigate();

  const handleBack = () => {
    Swal.fire({
      title: "¿Estás seguro de regresar?",
      text: "Si regresas perderás el progreso de la encuesta y no se podrá recuperar. ¿Deseas continuar?",
      showDenyButton: true,
      confirmButtonText: `Si`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(resetSurvey());
        navigate("/countries");
      }
    });
  };

  const saveResultsInDB = () => {
    dispatch(saveResults(parseInt(access?.id!), questionAnswers) as any);
  };

  useEffect(() => {
    dispatch(getQuestions() as any);
  }, [dispatch]);

  return isLoading ? (
    <SpinnerLoading />
  ) : (
    <div className="min-h-screen bg-gray-200 w-full flex flex-col justify-center items-center py-8 sm:my-0">
      <div className="flex justify-end w-4/5">
        <LogoutApp />
      </div>
      <div
        className="cursor-pointer"
        onClick={() => {
          handleBack();
        }}
      >
        <div className="flex items-center justify-center">
          <RiArrowLeftFill /> Volver
        </div>
      </div>
      <h3 className="my-8 text-grey font-bold">POR FAVOR LEER</h3>
      <p className="px-8">
        A continuación usted deberá ir seleccionando una a una las preguntas
        sobre la cual va a obtener la respuesta del encuestado. Al finalizar las
        4 preguntas se le facilitará un código que usted deberá ingresar en el
        sistema de encuestas para poder ligar las respuestas. Debe ingresar el
        mismo valor en cada pregunta.
      </p>
      <div className="flex flex-col flex-wrap gap-8 w-4/5 justify-center items-center mt-8">
        {questions.map((question, index) =>
          quesionsAnswered && quesionsAnswered.includes(question.id) ? (
            <div
              key={index}
              className="bg-green-300 p-4 rounded-lg shadow-md w-96 cursor-not-allowed"
            >
              <p className="text-center">{question.question}</p>
            </div>
          ) : (
            <div className="bg-white py-4 px-2 flex justify-center items-center flex-col gap-2 w-full rounded-lg">
                <p className="text-center">{question.question}</p>
              <div className="flex gap-2 justify-center">
                <button onClick={() => {
                  Swal.fire({
                    title: "¿Estás seguro?",
                    text: "¿Deseas finalizar la pregunta?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sí",
                    cancelButtonText: "Cancelar",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      dispatch(setQuestionAnswered(question.id) as any);
                    }
                  });
                }} className="bg-red-500 text-white p-2 rounded-lg">
                  No Aplica / No responde
                </button>
                <Link
                  to={`/country/${countryId}/question/${question.id}/supermarkets`}
                >
                  <button className="bg-green-500 text-white p-2 rounded-lg">
                    Responder
                  </button>
                </Link>
              </div>
            </div>
          )
        )}
      </div>
      {questions.length === quesionsAnswered.length && (
        <div className="mt-8">
          <button
            onClick={saveResultsInDB}
            className="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded"
          >
            Guardar resultados y obtener código
          </button>
        </div>
      )}
    </div>
  );
};

export default QuestionsSelector;
