import React from "react";
import { useNavigate } from "react-router-dom";
import { SpinnerLoading } from "../components/SpinnerLoading";
import { useFetch } from "../hooks/useFetch";

interface Country {
  id: number;
  img: string;
  name: string;
}

interface FetchData {
  countries?: Country[];
}

const Countries: React.FC = () => {
  const { data: datos, loading } = useFetch<FetchData>("catalog/countries");
  const navigate = useNavigate();

  return loading ? (
    <SpinnerLoading />
  ) : (
    <div className="h-screen bg-gray-200 w-full flex flex-col justify-center items-center py-8 sm:my-0">
      <h3 className="my-8 text-grey font-bold">Países</h3>
      <div className="flex flex-wrap gap-4 w-4/5 justify-center items-center">
        {datos?.countries?.map((item: Country) => (
          <button
            onClick={() => {
              navigate(`/country/${item.id}/questions`);
            }}
            key={item.id}
            className="flex flex-col w-36 justify-center items-center"
          >
            <img className="w-full" src={item.img} alt={item.name} />
            <p className="font-light text-center mt-2">{item.name}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Countries;
